import axios from 'axios';

export const signup = body => {
  return axios.post('/api/1.0/users', body);
};

export const login = creds => {
  return axios.post('/api/1.0/auth', creds);
};

export const logout = () => {
  return axios.post('/api/1.0/logout');
}

export const changeLanguage = language => {
  axios.defaults.headers['accept-language'] = language;
};

export const getUsers = (page = 0, size = 3) => {
  return axios.get(`/api/1.0/users?page=${page}&size=${size}`);
};

export const getPrices = (name = '', page = 0, size = 15) => {
  return axios.get(`/api/1.0/prices?name=${name}&page=${page}&size=${size}`);
};

export const getLogPrices = (productId = 0 ) => {
  return axios.get(`/api/1.0/log/price?productId=${productId}`);
};

export const getLogDetailPrices = (productOptionValueId = 0 ) => {
  return axios.get(`/api/1.0/log/price-detail?productOptionValueId=${productOptionValueId}`);
};

export const getPrices2 = (name = '', id = 0, page = 0, size = 15) => {
  let query = `/api/1.0/prices-by-category?id=${id}&name=${name}&page=${page}&size=${size}`;
  console.log("query:",query);
  return axios.get(query);
};

export const getGroups = () => {
  return axios.get(`/api/1.0/groups`);
};

export const setAuthorizationHeader = ({ isLoggedIn, token }) => {
  if (isLoggedIn) {
    const authorizationHeaderValue = `Bearer ${token}`;
    axios.defaults.headers['Authorization'] = authorizationHeaderValue;
  } else {
    delete axios.defaults.headers['Authorization'];
  }
};

export const getUser = username => {
  return axios.get(`/api/1.0/users/${username}`);
};

export const updateUser = (username, body) => {
  return axios.put(`/api/1.0/users/${username}`, body);
};

export const postHoax = hoax => {
  return axios.post('/api/1.0/hoaxes', hoax);
};

export const postPriceMaster = price => {
  return axios.post(`/api/1.0/price-master?id=${price.productId}`, price);
}

export const postPriceDetail = detail => {
  return axios.post(`/api/1.0/price-detail?id=${detail.productOptionValueId}`, detail);
}

export const postPriceAll = price => {
  return axios.post('/api/1.0/price-all', price);
}

export const postPriceGroupAll = price => {
  return axios.post('/api/1.0/price-group-all', price);
}

export const getHoaxes = (username, page = 0) => {
  const path = username ? `/api/1.0/users/${username}/hoaxes?page=` : '/api/1.0/hoaxes?page=';
  return axios.get(path + page);
};

export const getOldHoaxes = (id, username) => {
  const path = username ? `/api/1.0/users/${username}/hoaxes/${id}` : `/api/1.0/hoaxes/${id}`;
  return axios.get(path);
};

export const getNewHoaxCount = (id, username) => {
  const path = username ? `/api/1.0/users/${username}/hoaxes/${id}?count=true` : `/api/1.0/hoaxes/${id}?count=true`;
  return axios.get(path);
};

export const getNewHoaxes = (id, username) => {
  const path = username ? `/api/1.0/users/${username}/hoaxes/${id}?direction=after` : `/api/1.0/hoaxes/${id}?direction=after`;
  return axios.get(path);
};

export const postHoaxAttachment = attachment => {
  return axios.post('/api/1.0/hoax-attachments', attachment);
};

export const deleteHoax = id => {
  return axios.delete(`/api/1.0/hoaxes/${id}`);
};

export const deleteUser = username => {
  return axios.delete(`/api/1.0/users/${username}`);
};
