import React, { useState } from 'react';
import { getLogPrices } from '../api/apiCalls';
import Spinner from './Spinner';

const MyMasterDialog = props => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const setGetLogPrices = async () => {
    try {
      setLoading(true);
      const response = await getLogPrices(props.productId);
      setData(response.data);
      setLoading(!true);      
    } catch (error) {
      console.log(error);
      setLoading(!true);
    }
  };

  const tarih = (dat) => {
    try {
    let d = new Date(dat);
    return d.toLocaleString("tr-TR");
    }
    catch(error) {
      return dat;
    }
  }

  if (!props.productId) {
    return (<></>);
  }

  return (
    <>
      <i className="far fa-clipboard" style={{cursor:"pointer", color:"blue", marginRight:"5px"}} data-toggle="modal" onClick={() => setGetLogPrices()} data-target={`#exampleModal-${props.productId}`}></i>

      <div className="modal fade" id={`exampleModal-${props.productId}`} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Ana Fiyat / {props.model}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="list-group" style={{textAlign:"left",height:"250px",overflowY:"scroll"}}>
                {loading ? <Spinner /> : null}
                {data && data.map((dat,i) => (
                  <a key={dat.id} style={{fontSize:"small"}} className={i % 2 === 0 ? 'list-group-item list-group-item-action' : 'list-group-item list-group-item-secondary list-group-item-action'}>Fiy:{dat.price} - Adt:{dat.quantity} - Drm:{dat.status === 1 ? 'Aktif' : 'Pasif'} - Tar:{tarih(dat.updateDate)}</a>
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyMasterDialog;