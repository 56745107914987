import React, { useState, useEffect } from 'react';
import { getPrices2, postPriceAll, postPriceGroupAll, getGroups } from '../api/apiCalls';
import { useTranslation } from 'react-i18next';
import { useApiProgress } from '../shared/ApiProgress';
import Spinner from './Spinner';
import PriceListItem from './PriceListItem';

const PriceList = () => {
  const [errors, setErrors] = useState({});
  const [groupList, setGroupList] = useState({});
  const [grup, setGrup] = useState(0);
  const [search, setSearch] = useState('');

  const [page, setPage] = useState({
    content: [],
    size: 3,
    number: 0
  });

  const [loadFailure, setLoadFailure] = useState(false);

  const pendingApiCall = useApiProgress('get', `/api/1.0/prices-by-category?page`, true);
  const pendingApiCallAll = useApiProgress('post', `/api/1.0/price-all`, true);
  const pendingApiCallAllGroup = useApiProgress('post', `/api/1.0/price-group-all`, true);
  const pendingApiCallGroupsAll = useApiProgress('post', `/api/1.0/groups`, true);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      loadPrices();
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    loadPrices();
    loadGroups();
  }, []);

  const onClickNext = () => {
    const nextPage = page.number + 1;
    loadPrices(nextPage);
  };

  const onClickAll = async () => {
    let deger = prompt("Tüm fiyatları güncelleme için yüzde giriniz", Number("0"));
    if (deger === null) return;
    let tmp = parseFloat(deger.replace(/,/g, "."));
    if (tmp === null) return;
    tmp = Number(tmp).toFixed(4);

    const body = {
      price: tmp
    };
  
    try {
      await postPriceAll(body);
      setSearch(' ');
      setTimeout(() => { setSearch(''); }, 1000);
    } catch (error) {
      if (error.response.data.validationErrors) {
        setErrors(error.response.data.validationErrors);
      }
    }
  }

  const onClick2All = async () => {
    if (grup == 0) {
      alert("Lütfen tüm grupları update'i kullanınız!.");
      return;
    }

    let deger = prompt("Fiyatları güncelleme için yüzde giriniz", Number("0"));
    if (deger === null) return;
    let tmp = parseFloat(deger.replace(/,/g, "."));
    if (tmp === null) return;
    tmp = Number(tmp).toFixed(4);

    const body = {
      groupId: grup,
      price: tmp
    };
  
    try {
      await postPriceGroupAll(body);
      setSearch(' ');
      setTimeout(() => { setSearch(''); }, 1000);
    } catch (error) {
      if (error.response.data.validationErrors) {
        setErrors(error.response.data.validationErrors);
      }
    }
  }

  const onClickPrevious = () => {
    const previousPage = page.number - 1;
    loadPrices(previousPage);
  };

  const loadPrices = async (page) => {
    console.log("grup:",grup);
    setLoadFailure(false);
    try {
      const response = await getPrices2(search, grup, page);
      setPage(response.data);
    } catch (error) {
      setLoadFailure(true);
    }
  };


  const loadGroups = async () => {
    setLoadFailure(false);
    try {
      const response = await getGroups();
      setGroupList(response.data);
    } catch (error) {
      setLoadFailure(true);
    }
  };

  const { t } = useTranslation();
  const { content: contents, last, first } = page;

  let actionDiv = (
    <div>
      {first === false && (
        <button className="btn btn-sm btn-light" onClick={onClickPrevious}>
          {t('Previous')}
        </button>
      )}
      {last === false && (
        <button className="btn btn-sm btn-light float-right" onClick={onClickNext}>
          {t('Next')}
        </button>
      )}
    </div>
  );

  if (pendingApiCall) {
    actionDiv = <Spinner />;
  }

  return (
    <div className="card">
      <div className="row">
        <div className="col-md-4">
          <input value={search} onChange={e => setSearch(e.target.value) } className="form-control mr-sm-2" style={{marginTop:"4px",marginLeft:"2px"}} type="search" placeholder={t('Search')} aria-label={t('Search')}/>  
          {  pendingApiCall ? <Spinner /> : null }
        </div>
        <div className="col-md-4">
          <h6 className="card-header text-center">{t('Prices')}</h6>
        </div>
        <div className="col-md-4 form-inline">
          {pendingApiCallGroupsAll ? <Spinner /> : null }
          <select style={{marginTop:"4px",width:"140px"}} className="form-control" 
                onChange={(event)=>{ 
                    setGrup(event.target.value); 
                    setSearch(' ');
                    setTimeout(() => { setSearch(''); }, 1000);
                  }} value={grup}>
            <option value="0">All</option>
            {groupList.length > 0 && groupList.map(content => (
              <option key={content.id} value={content.id}>{content.name}</option>
            ))}
          </select>
          <button className="form-control btn btn-sm shadow-sm" style={{marginTop:"6px"}} onClick={onClick2All}>
            {t('Update Selected')}
          </button>
          { pendingApiCallAll ? <Spinner /> : 
          <button className="btn btn-sm shadow-sm float-right" style={{marginTop:"6px"}} onClick={onClickAll}>
              {t('Update All')}
          </button> }
        </div>
      </div>
      <div className="list-group-flush">
        <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Model</th>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">Price</th>
            <th scope="col">Price2</th>
            <th scope="col">Qty</th>
          </tr>
        </thead>
        <tbody>
        {contents.map(content => (
          <PriceListItem key={content.id} content={content} />
        ))}
        </tbody>
        </table>
      </div>
      {actionDiv}
      {loadFailure && <div className="text-center text-danger">{t('Load Failure')}</div>}
    </div>
  );
};

export default PriceList;
