import React from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../api/apiCalls';
import turkish from '../assets/turkish.png';
import usa from '../assets/usa.png';


const LanguageSelector = props => {
  const { i18n } = useTranslation();

  const onChangeLanguage = language => {
    i18n.changeLanguage(language);
    changeLanguage(language);
  };

  return (
    <div className="container" >
      <img
        src={turkish}
        alt="Turkish Flag" width="32" height="24"
        onClick={() => onChangeLanguage('tr')}
        style={{ cursor: 'pointer' }}
      ></img>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={usa} width="32" height="24" alt="USA Flag" onClick={() => onChangeLanguage('en')} style={{ cursor: 'pointer' }}></img>
    </div>
  );
};

export default LanguageSelector;
