import React, { useState, useEffect } from 'react';
import { useApiProgress } from '../shared/ApiProgress';
import { postPriceMaster, postPriceDetail } from '../api/apiCalls';
import Spinner from './Spinner';
import MyMasterDialog from './MyMasterDialog';
import MyDetailDialog from './MyDetailDialog';

const PriceListItem = props => {
  const { content } = props;

  const [errors, setErrors] = useState({});
  const [model, setModel] = useState(content.model);  
  
  const [name, setName] = useState(content.name);  
  const [quantity, setQuantity] = useState(content.quantity);  

  const [price, setPrice] = useState(content.price !== null ? Number(content.price).toFixed(4) : '0.0000');
  const [priceCarpan, setPriceCarpan] = useState(content.priceCarpan !== null ? Number(content.priceCarpan).toFixed(4) : '0.0000');  

  const [description, setDescription] = useState(content.description);  
  const [productId, setProductId] = useState(content.productId);  
  const [productOptionValueId, setProductOptionValueId] = useState(content.productOptionValueId);  

  const pendingApiCallMaster = useApiProgress('post', `/api/1.0/price-master?id=${content.productId}`, true);
  const pendingApiCallDetail = useApiProgress('post', `/api/1.0/price-detail?id=${content.productOptionValueId}`, true);

  const onMaster = async () => {
    let deger = price;
    if (deger === null) return;
    let tmp = parseFloat(deger.replace(/,/g, "."));
    if (tmp === null) return;
    tmp = Number(tmp).toFixed(4);

    const body = {
      productId,
      price: tmp
    };
  
    try {
      await postPriceMaster(body);
      setPrice(tmp);
    } catch (error) {
      if (error.response.data.validationErrors) {
        setErrors(error.response.data.validationErrors);
      }
    }
  };
  
  const onDetail = async () => {
    if (productOptionValueId) {
      
      let deger = priceCarpan;
      if (deger === null) return;
      let tmp = parseFloat(deger.replace(/,/g, "."));
      if (tmp === null) return;
      tmp = Number(tmp).toFixed(4);
      
      const body = {
        productId,
        productOptionValueId,
        priceCarpan: tmp
      };
    
      try {
        await postPriceDetail(body);
        setPriceCarpan(tmp);
      } catch (error) {
        if (error.response.data.validationErrors) {
          setErrors(error.response.data.validationErrors);
        }
      }    
    }
  };

  return (
      <tr>
        <th scope="row" style={{fontSize:"small"}}>{model}</th>
        <td style={{fontSize:"small"}}>{name}</td>
        <td style={{fontSize:"small"}}>{description !== null ? description : '-'}</td>
        <td>
          <MyMasterDialog productId={productId} model={model} />
          { pendingApiCallMaster ? <Spinner /> :
          <input style={{borderWidth:"0px",border:"none", boxShadow:"none", backgroundColor:"transparent"}} 
            value={price} onChange={e => setPrice(e.target.value)} onBlur={e => { onMaster(); }} /> }
        </td>
        <td>
          {productOptionValueId ? <MyDetailDialog productOptionValueId={productOptionValueId} model={model} /> : null }
          { pendingApiCallDetail ? <Spinner /> :
          <input style={{borderWidth:"0px",border:"none", boxShadow:"none", backgroundColor:"transparent"}} 
            value={priceCarpan} onChange={e => setPriceCarpan(e.target.value)} onBlur={e => { onDetail(); }} disabled={!productOptionValueId} /> }
        </td>
        <td style={{textAlign:"right",color:quantity > 0 ? "blue" : "red", fontSize:"small"}}>{quantity}</td>
      </tr>
  );
};

export default PriceListItem;
