import React from 'react';
import UserList from '../components/UserList';
import HoaxSubmit from '../components/HoaxSubmit';
import { useSelector } from 'react-redux';
import HoaxFeed from '../components/HoaxFeed';
import PriceList from '../components/PriceList';

const HomePage = () => {
  const { isLoggedIn } = useSelector(store => ({ isLoggedIn: store.isLoggedIn }));
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          { isLoggedIn ? <PriceList /> : <div style={{height:"500px"}}>&nbsp;</div> }
        </div>
      </div>
    </div>
  );
};

export default HomePage;
